import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import Nav from './components/Nav/Nav'
import Home from './paginas/Home/Home';
import Planos from './paginas/Planos/Planos';
import Contato from './paginas/Contato/Contato';
import Cliente from './paginas/Cliente/Cliente';
import AOS from 'aos';
import 'aos/dist/aos.css';



function App() {
  AOS.init();
  return (
    <BrowserRouter>
    <Nav/>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/planos" element={<Planos />} />
        <Route path="/contato" element={<Contato />} />
        <Route path="/cliente" element={<Cliente />} />
        <Route path="*" element={<div>Página não encontrada</div>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
