import styles from './FAQ.module.css'
import { IoIosArrowDroprightCircle } from "react-icons/io";

export default function FAQ(){
    return(
        <div className={styles.containerFAQ} id='faq'>
            <h2>FAQ</h2>
            <section className={styles.FAQ}>
                <details data-aos='fade-right'>
                    <summary className={styles.FAQTitulo}>
                        A DevZone desenvolve sites para qualquer tipo de negócio?
                        <span className={styles.summaryIcon}>
                            <IoIosArrowDroprightCircle 
                                size={20}
                            />
                        </span>
                    </summary>
                    <p className={styles.FAQResposta}>
                    Sim! Atendemos desde pequenas empresas até grandes corporações, adaptando nossas soluções às necessidades de cada cliente.
                    </p>
                </details>

                <details data-aos='fade-right'>
                    <summary className={styles.FAQTitulo}>
                        Os sites da DevZone são responsivos e rápidos?
                        <span className={styles.summaryIcon}>
                            <IoIosArrowDroprightCircle 
                                size={20}
                            />
                        </span>
                    </summary>
                    <p className={styles.FAQResposta}>
                    Sim! Todos os nossos sites são desenvolvidos para se adaptarem a diferentes dispositivos e garantimos otimização de desempenho para tempos de carregamento rápidos.
                    </p>
                </details>

                <details data-aos='fade-right'>
                    <summary className={styles.FAQTitulo}>
                        Posso usar minha própria hospedagem e domínio? 
                        <span className={styles.summaryIcon}>
                            <IoIosArrowDroprightCircle 
                                size={20}
                            />
                        </span>
                    </summary>
                    <p className={styles.FAQResposta}>
                    Sim! Se você já possui hospedagem e domínio, podemos desenvolver o site e configurá-lo na sua plataforma.
                    </p>
                </details>

                <details data-aos='fade-right'>
                    <summary className={styles.FAQTitulo}>
                        Além do desenvolvimento de sites, a DevZone oferece outros serviços digitais? 
                        <span className={styles.summaryIcon}>
                            <IoIosArrowDroprightCircle 
                                size={20}
                            />
                        </span>
                    </summary>
                    <p className={styles.FAQResposta}>
                    Também trabalhamos com a criação de LinkMes personalizados para suas redes sociais e também desenvolvemos logotipos para sua marca caso não tenha ou queira dar uma repaginada.
                    </p>
                </details>

                <details data-aos='fade-right'>
                    <summary className={styles.FAQTitulo}>
                    Como funciona o processo de desenvolvimento de um site na DevZone?
                        <span className={styles.summaryIcon}>
                            <IoIosArrowDroprightCircle 
                                size={20}
                            />
                        </span>
                    </summary>
                    <p className={styles.FAQResposta}>
                    Nosso processo envolve: <br/>

                    <b>1: Reunião inicial – </b>Entendemos suas necessidades e objetivos.<br/>
                    <b>2: Planejamento e design – </b>Criamos um layout alinhado à sua identidade visual.<br/>
                    <b>3: Desenvolvimento – </b>Codificamos o site com as melhores práticas.<br/>
                    <b>4: Testes e ajustes – </b>Garantimos que tudo funcione perfeitamente.<br/>
                    <b>5: Lançamento – </b>Publicamos seu site e oferecemos suporte pós-lançamento.<br/>
                    </p>
                </details>
            </section>
        </div>
    )
}