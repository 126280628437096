import styles from './Planos.module.css'
import Info from "../../components/Info/Info";
import Footer from "../../components/Footer/Footer";
import Timeline from './ComponentsPlans/Timeline/Timeline';
import DetailsPlanos from './ComponentsPlans/DetailsPlanos/DetailsPlanos';
import DetailsService from './ComponentsPlans/DetailsServices/DetailsService';
import DetailsExtra from './ComponentsPlans/DetailsExtra/DetailsExtra';

export default function Planos(){
    return (
        <div className={styles.container}>
            <Timeline/>
            <DetailsPlanos/>
            <DetailsService/>
            <DetailsExtra/>
            <Info/>
            <Footer/>
        </div>
    )
}