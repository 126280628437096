import styles from './About.module.css'
import discoGiratorio from './disco.png'

export default function About(){
    return(
        <div className={styles.container} id='about'>
            <div className={styles.aboutSection}>
                <div className={styles.discoGiratorioSection}  data-aos="fade-left">
                    <img src={discoGiratorio} alt='imagem giratoria devzone' className={styles.disco}></img>
                </div>
                <div className={styles.textoSection}>
                    <h2 className={styles.titulo}>Sobre a <span className={styles.tituloMarcado}>DevZone</span></h2>
                    <p className={styles.texto}>
                        A DevZone Innovations é uma startup inovadora 
                        que desenvolve sites personalizados para negócios 
                        de todos os tamanhos. Focada em criar experiências 
                        digitais que geram resultados, a empresa se destaca 
                        por seu compromisso com a inovação, excelência e 
                        por atender às necessidades específicas de cada cliente.
                    </p>
                </div>
            </div>
            <div className={styles.botaoOrçamento}  data-aos="fade-down">
                <a href='https://wa.me/5511912125307'>Fale conosco</a>
            </div>
        </div>
    )
}