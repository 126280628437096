import styles from './DetailsService.module.css'
import { FaCheck } from "react-icons/fa";

export default function DetailsService(){
    return(
        <div className={styles.container}>
            <h1>Conheça nossos <br/><span className={styles.textoGrifado}>Serviços de Segurança</span></h1>
            <div className={styles.containerService}>
                <div className={styles.detailsService}>
                    <h2>Manuntenção de Sites</h2>
                    <span className={styles.guardian}>Guardian Zone</span>
                    <div className={styles.linhaVertical}></div>
                    <div className={styles.listService}>
                        <ul>
                            <li>
                                <i>
                                    <FaCheck 
                                        color='#838383'
                                    />
                                </i>
                                Manuntenção de Banco de Dados
                            </li>
                            <li>
                                <i>
                                    <FaCheck 
                                        color='#838383'
                                    />
                                </i>
                                Testes e Verificação de Links
                            </li>
                            <li>
                                <i>
                                    <FaCheck 
                                        color='#838383'
                                    />
                                </i>
                                Suporte Técnico por 15 dias
                            </li>
                        </ul>
                    </div>
                </div>

                <div className={styles.detailsService}>
                    <h2>Atualização de Sites</h2>
                    <span className={styles.update}>Update Zone</span>
                    <div className={styles.linhaVertical}></div>
                    <div className={styles.listService}>
                        <ul>
                            <li>
                                <i>
                                    <FaCheck 
                                        color='#ff67de'
                                    />
                                </i>
                                Atualização de Conteúdos
                            </li>
                            <li>
                                <i>
                                    <FaCheck 
                                        color='#ff67de'
                                    />
                                </i>
                                Atualização de Design e Layout
                            </li>
                            <li>
                                <i>
                                    <FaCheck 
                                        color='#ff67de'
                                    />
                                </i>
                                Alterações cobradas por seção
                            </li>
                            <li>
                                <i>
                                    <FaCheck 
                                        color='#ff67de'
                                    />
                                </i>
                                Suporte Técnico por 15 dias
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className={styles.btnWapp}>
                <a href='https://wa.me/5511912125307'>Faça o seu orçamento</a>
            </div>
        </div>
    )
}