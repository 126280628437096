import { Link } from 'react-router-dom'
import styles from './ExtraPlans.module.css'

export default function ExtraPlans(){
    return(
        <div className={styles.containerExtraPlans}>
            <h2>Nossos <span className={styles.textoGrifado}>Serviços Adicionais</span></h2>
            <div className={styles.ExtraPlans}>

                <div className={styles.detailsBubble} data-aos='flip-down'>
                    <h3>Bubble Zone</h3>
                    <p>
                        Este serviço inclui a criação de um "LinkMe" personalizado, 
                        ideal para reunir links de suas redes sociais ou sites em 
                        um único lugar. Com um design exclusivo, você poderá 
                        compartilhar todos os seus conteúdos em uma só página,  
                        dando um toque profissional à sua presença online.
                    </p>
                </div>

                <div className={styles.detailsCreative} data-aos='flip-right'>
                    <h3>Creative Zone</h3>
                    <p>
                        Este serviço inclui a criação de três logotipos personalizados 
                        para a sua empresa. Você receberá um logotipo apenas com o 
                        letreiro (nome da empresa), outro somente com o símbolo ou 
                        imagem da marca, e um terceiro que combina ambos. Isso oferece 
                        flexibilidade para usar os logotipos em diferentes contextos.
                    </p>
                </div>
            </div>
            <div className={styles.btns}>
                <div className={styles.btnWapp}>
                    <a href='https://wa.me/5511912125307'>Faça o seu orçamento</a>
                </div>
                <div className={styles.btnPlanos}>
                    <Link
                        className={styles.link}
                        to="/planos">
                            Ver mais
                    </Link>
                </div>
            </div>
        </div>
    )
}