import About from "../../components/About/About";
import Beneficios from "../../components/Beneficios/Beneficios";
import ExtraPlans from "../../components/ExtraPlans/ExtraPlans";
import FAQ from "../../components/FAQ/FAQ";
import Footer from "../../components/Footer/Footer";
import HomeMain from "../../components/HomeMain/HomeMain";
import HowWorks from "../../components/HowWorks/HowWorks";
import Info from "../../components/Info/Info";
import Plans from "../../components/Plans/Plans";
import PlansServices from "../../components/PlansServices/PlansServices";


export default function Home(){
    return (
        <div>
            <HomeMain/>
            <About/>
            <Beneficios/>
            <HowWorks/>
            <Plans/>
            <PlansServices/>
            <ExtraPlans/>
            <FAQ/>
            <Info/>
            <Footer/>
        </div>
    )
}