import styles from './Timeline.module.css'
import { MdKeyboardDoubleArrowDown } from "react-icons/md";

export default function Timeline(){
    return(
        <div className={styles.container} id='timeline'>
            <h2>Passo a passo do <br/><span className={styles.textoGrifado}>seu projeto</span></h2>
            <div className={styles.containerDefiniçao}>
                <div className={styles.definiçaoTimeline} data-aos='fade-right'>
                    <h3>Bate-papo</h3>
                    <p>
                        Etapa onde programamos uma reunião
                        estratégica para aprofundar nossa 
                        compreensão sobre os valores, 
                        objetivos e identidade da sua empresa.
                    </p>
                </div>

                <div className={styles.seta}>
                    <span>
                        <MdKeyboardDoubleArrowDown 
                            size={40}
                            color='#ff863b'
                        />
                    </span>
                </div>

                <div className={styles.definiçaoTimeline} data-aos='fade-down'>
                    <h3>Prototipação</h3>
                    <p>
                        Etapa onde criamos um protótipo do seu site. A partir 
                        dessa etapa podemos ter uma noção de como você imagina o seu 
                        site e executarmos todas as ideias, requisitos e dinâmicas do 
                        seu site.
                    </p>
                </div>

                <div className={styles.seta}>
                    <span>
                        <MdKeyboardDoubleArrowDown 
                            size={40}
                            color='#ff863b'
                        />
                    </span>
                </div>

                <div className={styles.definiçaoTimeline} data-aos='fade-left'>
                    <h3>Desenvolvimento</h3>
                    <p>
                    Etapa dedicada ao desenvolvimento do seu site, empregando 
                    as mais avançadas tecnologias para garantir uma 
                    solução moderna, eficiente e alinhada às suas necessidades.
                    </p>
                </div>

                <div className={styles.seta}>
                    <span>
                        <MdKeyboardDoubleArrowDown 
                            size={40}
                            color='#ff863b'
                        />
                    </span>
                </div>

                <div className={styles.definiçaoTimeline} data-aos='fade-left'>
                    <h3>Ajustes</h3>
                    <p>
                    Etapa dedicada aos ajustes, garantindo que sua visão seja plenamente 
                    atendida. Caso algum elemento não tenha ficado como o esperado, 
                    refinamos o design UX/UI e realizamos as melhorias necessárias no desenvolvimento.
                    </p>
                </div>

                <div className={styles.seta}>
                    <span>
                        <MdKeyboardDoubleArrowDown 
                            size={40}
                            color='#ff863b'
                        />
                    </span>
                </div>

                <div className={styles.definiçaoTimeline} data-aos='fade-left'>
                    <h3>Postagem</h3>
                    <p>
                    Etapa em que publicamos o seu site e realizamos a indexação no Google, garantindo visibilidade 
                    online. Além disso, monitoramos o desempenho para otimizações contínuas.
                    </p>
                </div>

                <div className={styles.seta}>
                    <span>
                        <MdKeyboardDoubleArrowDown 
                            size={40}
                            color='#ff863b'
                        />
                    </span>
                </div>

                <div className={styles.definiçaoTimeline} data-aos='fade-left'>
                    <h3>Feedback</h3>
                    <p>
                    Nesta etapa, sua participação é essencial! Valorizamos seu feedback para garantir que sua 
                    experiência seja a melhor possível. Além disso, ao compartilhar sua opinião positiva e 
                    nos marcar em suas redes sociais, você nos ajuda a alcançar mais pessoas e fortalecer nossa 
                    comunidade. Seu apoio é fundamental para continuarmos oferecendo soluções inovadoras e de alta qualidade.
                    </p>
                </div>
            </div>
            <div className={styles.espaço}></div>
        </div>
    )
}