import styles from './Beneficios.module.css'
import { CiGrid31 } from "react-icons/ci";
import { FaUserCheck } from "react-icons/fa6";
import { BsDisplay } from "react-icons/bs";
import { FaGoogle } from "react-icons/fa";
import { RiPencilRuler2Fill } from "react-icons/ri";
import { IoFlash } from "react-icons/io5";
import { LiaCoinsSolid } from "react-icons/lia";

import { ImStarFull } from "react-icons/im";

export default function Beneficios(){
    return(
        <div className={styles.container} id='benefits'>
            <div className={styles.beneficioTitulo}>
                <h2>Quais os benefícios de<br/> <span className={styles.textoMarcado}>contratar um programador</span></h2>
            </div>
            <div className={styles.banners}>
            <div className={styles.banner} data-aos="fade-down">
                    <div className={styles.numeração}>
                        <span>01</span>
                    </div>
                    <div className={styles.icone}>
                        <LiaCoinsSolid 
                            size={80}
                        />
                    </div>
                    <div className={styles.tituloBanner}>
                        <h2>Pagamento Único</h2>
                        <p>
                            Não exigimos mensalidades, planos de fidelidade ou taxas surpresa. 
                            Você não precisa pagar um centavo a mais pelo seu site após a criação.
                        </p>
                    </div>
                    <div className={styles.iconeMovimento}>
                        <ImStarFull 
                            size={30}
                        />
                    </div>
                </div>

                <div className={styles.banner} data-aos="fade-down">
                    <div className={styles.numeração}>
                        <span>02</span>
                    </div>
                    <div className={styles.icone}>
                        <CiGrid31 
                            size={80}
                        />
                    </div>
                    <div className={styles.tituloBanner}>
                        <h2>Layout Personalizado</h2>
                        <p>
                            O layout é exclusivo e desenvolvido por especialistas. 
                            Formas, cores, imagens, ícones. Tudo isso é pensado 
                            nos mínimos detalhes, especialmente para você.
                        </p>
                    </div>
                    <div className={styles.iconeMovimento}>
                        <ImStarFull 
                            size={30}
                        />
                    </div>
                </div>

                <div className={styles.banner}  data-aos="fade-down">
                    <div className={styles.numeração}>
                        <span>03</span>
                    </div>
                    <div className={styles.icone}>
                        <FaUserCheck 
                            size={80}
                        />
                    </div>
                    <div className={styles.tituloBanner}>
                        <h2>Experiência do usuário</h2>
                        <p>
                            Estratégias de UX/UI Design focadas em otimizar
                            a experiência do usuário, melhorar a usabilidade, 
                            navegação e conversão.
                        </p>
                    </div>
                    <div className={styles.iconeMovimento}>
                        <ImStarFull 
                            size={30}
                        />
                    </div>
                </div>

                <div className={styles.banner}  data-aos="fade-down">
                    <div className={styles.numeração}>
                        <span>04</span>
                    </div>
                    <div className={styles.icone}>
                        <BsDisplay 
                            size={80}
                        />
                    </div>
                    <div className={styles.tituloBanner}>
                        <h2>Site responsivo</h2>
                        <p>
                            O layout é exclusivo e desenvolvido por especialistas. 
                            Formas, cores, imagens, ícones. Tudo isso é pensado 
                            nos mínimos detalhes, especialmente para você.
                        </p>
                    </div>
                    <div className={styles.iconeMovimento}>
                        <ImStarFull 
                            size={30}
                        />
                    </div>
                </div>

                <div className={styles.banner}  data-aos="fade-down">
                    <div className={styles.numeração}>
                        <span>05</span>
                    </div>
                    <div className={styles.icone}>
                        <FaGoogle 
                            size={80}
                        />
                    </div>
                    <div className={styles.tituloBanner}>
                        <h2>Pronto para o Google</h2>
                        <p>
                            Ao desenvolver o site, seguimos todas as diretrizes
                            e critérios impostos pelo Google para que sua empresa 
                            apareça na maior rede de pesquisa.
                        </p>
                    </div>
                    <div className={styles.iconeMovimento}>
                        <ImStarFull 
                            size={30}
                        />
                    </div>
                </div>

                <div className={styles.banner}  data-aos="fade-down">
                    <div className={styles.numeração}>
                        <span>06</span>
                    </div>
                    <div className={styles.icone}>
                        <RiPencilRuler2Fill 
                            size={80}
                        />
                    </div>
                    <div className={styles.tituloBanner}>
                        <h2>Personalização total</h2>
                        <p>
                            Desenvolvemos todos os sites de forma personalizada, 
                            layouts 100% exclusivos para a sua marca. Uma aparência 
                            moderna e profissional, para transmitir confiança a seus visitantes.
                        </p>
                    </div>
                    <div className={styles.iconeMovimento}>
                        <ImStarFull 
                            size={30}
                        />
                    </div>
                </div>

                <div className={styles.banner}>
                    <div className={styles.numeração}>
                        <span>07</span>
                    </div>
                    <div className={styles.icone}>
                        <IoFlash 
                            size={80}
                        />
                    </div>
                    <div className={styles.tituloBanner}>
                        <h2>Páginas rápidas</h2>
                        <p>
                            Carregamento das páginas de forma rápida 
                            para aumentar o número de conversões, através 
                            de otimização e servidor cloud de alta performance.
                        </p>
                    </div>
                    <div className={styles.iconeMovimento}>
                        <ImStarFull 
                            size={30}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}