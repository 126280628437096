
import { Link } from 'react-router-dom'
import styles from './Info.module.css'
import logo from './logo.png'
import { LuClock3 } from "react-icons/lu";
import { BsInstagram } from "react-icons/bs";
import { IoLogoLinkedin } from "react-icons/io";
import { IoLogoGithub } from "react-icons/io5";
import { FaBehance } from "react-icons/fa";


export default function Info(){
    return(
        <div className={styles.container}>
            <div className={styles.secao1}>
                <a href='#main' className={styles.img}>
                    <img src={logo} alt="Logo da empresa"/>
                </a>
                <a href='#'>
                    Política de Privacidade
                </a>
                <a href='#'>
                    Termos de Uso
                </a>
            </div>
            <div className={styles.secao2}>
                <h2>Institucional</h2>
                        <Link
                            className={styles.link}
                            to="/">
                                Home
                        </Link>
                        <a href='#about'>
                            Sobre
                        </a>
                        <a href='#benefits'>
                            Benefícios
                        </a>
                        <a href='#howWorks'>
                            Como funciona
                        </a>
                        <Link
                            className={styles.link}
                            to="/planos">
                                Serviços
                        </Link>
                        <a href='#faq'>
                            FAQ
                        </a>
                        <Link
                            className={styles.link}
                            to="/cliente">
                                Clientes
                        </Link>
                        <Link
                            className={styles.linkContato}
                            to="/contato">
                                Contato
                        </Link>
            </div>
            <div className={styles.secao3}>
                <h2>Horário de <br/>funcionamento</h2>
                <p>Segunda - Sexta</p>
                <span>
                    <LuClock3 
                        color='c0c0c0'
                    /> 09 AM - 06 PM
                </span>
            </div>
            <div className={styles.secao4}>
                <h2>Parceiros</h2>
                <a href='https://alnidigital.com' target="_blank" rel="noopener noreferrer">
                    ALNI Digital
                </a>
            </div>
            <div className={styles.secao5}>
                <h2>Nos acompanhe</h2>
                <div className={styles.redes}>
                    <a href='https://instagram.com/__devzone/' target="_blank" rel="noopener noreferrer">
                        <BsInstagram 
                            size={24}
                        />
                    </a>
                    <a href='https://linkedin.com/in/marcosvclementec/' target="_blank" rel="noopener noreferrer">
                        <IoLogoLinkedin 
                            size={24}
                        />
                    </a>
                    <a href='https://github.com/viinii3' target="_blank" rel="noopener noreferrer">
                        <IoLogoGithub 
                            size={28}
                        />
                    </a>
                    <a href='https://behance.net/marcosvinicius852' target="_blank" rel="noopener noreferrer">
                        <FaBehance 
                            size={28}
                        />
                    </a>
                </div>
            </div>
        </div>
    )
}