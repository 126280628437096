import styles from './DetailsPlanos.module.css'
import { FaCheck } from "react-icons/fa";

export default function DetailsPlanos(){
    return(
        <div className={styles.container}>
            <h1>Conheça nossos <br/><span className={styles.textoGrifado}>Serviços</span></h1>
            <div className={styles.containerPlans}>
                <div className={styles.details}>
                    <h2>Landing Pages</h2>
                    <span className={styles.essential}>Essential Zone</span>
                    <div className={styles.linhaVertical}></div>
                    <div className={styles.list}>
                        <ul>
                            <li>
                                <i>
                                    <FaCheck 
                                        color='#FF9900'
                                    />
                                </i>
                                Até 6 páginas
                            </li>
                            <li>
                                <i>
                                    <FaCheck 
                                        color='#FF9900'
                                    />
                                </i>
                                Projeto Personalizado
                            </li>
                            <li>
                                <i>
                                    <FaCheck 
                                        color='#FF9900'
                                    />
                                </i>
                                Design Responsivo
                            </li>
                            <li>
                                <i>
                                    <FaCheck 
                                        color='#FF9900'
                                    />
                                </i>
                                Integração com as Redes Sociais
                            </li>
                            <li>
                                <i>
                                    <FaCheck 
                                        color='#FF9900'
                                    />
                                </i>
                                SEO otimizado
                            </li>
                            <li>
                                <i>
                                    <FaCheck 
                                        color='#FF9900'
                                    />
                                </i>
                                Formulário de Contato
                            </li>
                            <li>
                                <i>
                                    <FaCheck 
                                        color='#FF9900'
                                    />
                                </i>
                                Botôes de Ação
                            </li>
                        </ul>
                    </div>
                </div>

                <div className={styles.details}>
                    <h2>Sites Institucionais</h2>
                    <span className={styles.premium}>Premium Zone</span>
                    <div className={styles.linhaVertical}></div>
                    <div className={styles.list}>
                        <ul>
                            <li>
                                <i>
                                    <FaCheck 
                                        color='#1E90FF'
                                    />
                                </i>
                                Até 10 páginas
                            </li>
                            <li>
                                <i>
                                    <FaCheck 
                                        color='#1E90FF'
                                    />
                                </i>
                                Projeto Personalizado
                            </li>
                            <li>
                                <i>
                                    <FaCheck 
                                        color='#1E90FF'
                                    />
                                </i>
                                Design Responsivo
                            </li>
                            <li>
                                <i>
                                    <FaCheck 
                                        color='#1E90FF'
                                    />
                                </i>
                                Integração com as Redes Sociais
                            </li>
                            <li>
                                <i>
                                    <FaCheck 
                                        color='#1E90FF'
                                    />
                                </i>
                                SEO otimizado
                            </li>
                            <li>
                                <i>
                                    <FaCheck 
                                        color='#1E90FF'
                                    />
                                </i>
                                Formulário de Contato
                            </li>
                            <li>
                                <i>
                                    <FaCheck 
                                        color='#1E90FF'
                                    />
                                </i>
                                Botôes de Ação
                            </li>
                            <li>
                                <i>
                                    <FaCheck 
                                        color='#1E90FF'
                                    />
                                </i>
                                Ideal para autonômos e empresas
                            </li>
                            <li>
                                <i>
                                    <FaCheck 
                                        color='#1E90FF'
                                    />
                                </i>
                                Suporte Técnico por 30 dias
                            </li>
                        </ul>
                    </div>
                </div>

                <div className={styles.details}>
                    <h2>Lojas Virtuais</h2>
                    <span className={styles.elite}>Elite Zone</span>
                    <div className={styles.linhaVertical}></div>
                    <div className={styles.list}>
                        <ul>
                            <li>
                                <i>
                                    <FaCheck 
                                        color='#8c00c9'
                                    />
                                </i>
                                Número de Páginas Ilimitado
                            </li>
                            <li>
                                <i>
                                    <FaCheck 
                                        color='#8c00c9'
                                    />
                                </i>
                                E-commerce Completo
                            </li>
                            <li>
                                <i>
                                    <FaCheck 
                                        color='#8c00c9'
                                    />
                                </i>
                                Integração de Pagamentos Online
                            </li>
                            <li>
                                <i>
                                    <FaCheck 
                                        color='#8c00c9'
                                    />
                                </i>
                                Integração com as Redes Sociais
                            </li>
                            <li>
                                <i>
                                    <FaCheck 
                                        color='#8c00c9'
                                    />
                                </i>
                                SEO para Comércio eletônico
                            </li>
                            <li>
                                <i>
                                    <FaCheck 
                                        color='#8c00c9'
                                    />
                                </i>
                                Design Resposivo, Moderno e Atraente
                            </li>
                            <li>
                                <i>
                                    <FaCheck 
                                        color='#8c00c9'
                                    />
                                </i>
                                Segurança Reforçada
                            </li>
                            <li>
                                <i>
                                    <FaCheck 
                                        color='#8c00c9'
                                    />
                                </i>
                                Banco de Dados Otimizado
                            </li>
                            <li>
                                <i>
                                    <FaCheck 
                                        color='#8c00c9'
                                    />
                                </i>
                                Carrinho de Compras Seguro
                            </li>
                            <li>
                                <i>
                                    <FaCheck 
                                        color='#8c00c9'
                                    />
                                </i>
                                Suporte Técnico por 60 dias
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className={styles.btnWapp}>
                <a href='https://wa.me/5511912125307'>Faça o seu orçamento</a>
            </div>
        </div>
    )
}