import Info from "../../components/Info/Info";
import Footer from "../../components/Footer/Footer";
import DetailsContato from "./ComponentsContato/DetailsContato/DetailsContato";

export default function Contato(){
    return (
        <div>
            <DetailsContato/>
            <Info/>
            <Footer/>
        </div>
    )
}