import styles from './Cliente.module.css'
import Info from "../../components/Info/Info";
import Footer from "../../components/Footer/Footer";

export default function Cliene(){
    return (
        <div>
            <div className={styles.container}>
            <h1>Seção em desenvolvimento ...</h1>
            <p>
                Você pode acompanhar nossos clientes por enquanto <br/>
                través do nosso instagram:
            </p>

            <a className={styles.link} href='https://instagram.com/__devzone/' target="_blank" rel="noopener noreferrer">
                Vem conferir
            </a>
            </div>
            <Info/>
            <Footer/>
        </div>
    )
}