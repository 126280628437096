import styles from './PlansServices.module.css'

export default function PlansServices(){
    return(
        <div className={styles.containerPlansServices}>
            <h2>Nossos <span className={styles.textoGrifado}>Serviços de Segurança</span></h2>
            <div className={styles.PlansServices}>
                <div className={styles.detailsGuardian} data-aos='flip-left'>
                    <h3>Guardian Zone</h3>
                    <p>
                        Este plano é ideal para quem precisa de uma manutenção
                        no site, com testes e verificações precisas e um suporte técnico 
                        por 15 dias após a entrega.
                    </p>
                </div>

                <div className={styles.detailsUpdate} data-aos='flip-down'>
                    <h3>Update Zone</h3>
                    <p>
                        Este plano é ideal para quem precisa de atualizações constantes 
                        no site, como a adição de novas páginas ou conteúdos. Ele pode 
                        ser aplicado tanto em sites criados pela DevZone quanto em sites 
                        desenvolvidos por outras empresas.
                    </p>
                </div>
            </div>
        </div>
    )
}