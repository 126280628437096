import { useTypewriter, Cursor } from 'react-simple-typewriter'
import styles from './HomeMain.module.css'
import divisoria from './divisoria.png'

export default function HomeMain(){
    const [text] = useTypewriter({
        words: ["DevZone"],
        loop: true,
        typeSpeed: 150,
        deleteSpeed: 50,
    });

    return(
        <div className={styles.container} id='main'>
            <div className={styles.containerMain}>
                <h1 className={styles.title}  data-aos="fade-down">
                    {text}
                    <span className={styles.span}>
                        <Cursor cursorStyle="_" cursorBlinking={false} />
                    </span>
                </h1>
                <p className={styles.subtitle}  data-aos="fade-up">
                    Empresa de criação de sites <span className={styles.subtitle1}>com experiência internacional.</span>
                </p>
                <p className={styles.subtitle2}  data-aos="fade-up">
                    Empresa especialista em criação de sites de alta qualidade do jeito que o seu negócio precisa.
                </p>
                <div className={styles.botaoOrçamento}>
                    <a href='https://wa.me/5511912125307'>Faça um orçamento</a>
                </div>
            </div>
            <div className={styles.divisoria}>
                <img src={divisoria} alt='divisoria da pagina'/>
            </div>
        </div>
    )
}