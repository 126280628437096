import styles from './DetailsContato.module.css'
import { useState } from 'react'
import emailjs from '@emailjs/browser'

export default function DetailsContato(){
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [tel, setTel] = useState('')
    const [message, setMessage] = useState('')
    const [title, setTitle] = useState('')

    function sendEmail(e){
        e.preventDefault();

        const templateParams = {
            name: name,
            message: message,
            tel: tel,
            email: email,
            title: title
        }

        emailjs.send(
            "service_4by1fdb", /* identificado no campo email servise */
            "template_0adw2oy", /* identificado no campo email template */
            templateParams, 
            "RD36CWZl1WzA2xHFo" /* identificado no campo account */
        )
        .then((response) => {
            console.log('SUCCESS!', response.status, response.text);
            setName('')
            setEmail('')
            setMessage('')
            setTel('')
            setTitle('')
        }, (err) => {
            console.log('FAILED: ', err);
        })
    }

    return(
        <div className={styles.contato} data-aos="fade-down">
            <div className={styles.contatoHeader}>
                <h1>Entre em <span className={styles.textoGrifado}>Contato</span></h1>
            </div>
            <p>
                Se você tiver alguma dúvida ou quiser saber mais 
                sobre nossos serviços ou sobre algum dos nossos parceiros, 
                não hesite em entrar em contato. Nossa equipe está aqui 
                para ajudar você a alcançar seus objetivos da melhor forma.
            </p>
            <form onSubmit={sendEmail} className={styles.contatoForm}>
                <div className={styles.contatoForm1}>
                    <div className={styles.blockForm1}>
                        <input 
                            type="text"
                            required 
                            placeholder="* Seu nome"
                            onChange={(e) => setName(e.target.value)}
                            value={name}
                        />
                        <br/>
                        <input 
                            type="tel" 
                            required 
                            placeholder="* Seu celular"
                            onChange={(e) => setTel(e.target.value)}
                            value={tel}
                        />
                    </div>
                    <div className={styles.blockForm2}>
                        <input 
                            type="email" 
                            required 
                            placeholder="* Seu email"
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                        />
                        <br/>
                        <input
                            type='text'
                            required 
                            placeholder="* Qual seria o assunto"
                            onChange={(e) => setTitle(e.target.value)}
                            value={title}
                        />
                    </div>
                </div>
                <div className={styles.contatoForm2}>
                    <textarea 
                        required 
                        placeholder="* No que podemos ajudar ..."
                        onChange={(e) => setMessage(e.target.value)}
                        value={message}
                    />
                </div>
                <div className={styles.btnForm}>
                    <button type="submit">Enviar</button>
                </div>
            </form>
        </div>
        
    )
}