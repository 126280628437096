import styles from './HowWorks.module.css'

export default function HowWorks(){
    return(
        <div className={styles.containerHowWorks} id='howWorks'>
            <h2>Como funciona o <br/><span className={styles.textoGrifado}>desenvolvimento nas 3 etapas</span></h2>
            <div className={styles.containerDefiniçao}>
                <div className={styles.definiçao} data-aos='fade-right'>
                    <h3><span className={styles.textoGrifado}><span className={styles.numeração}>1: </span>Bate papo</span> do seu negócio</h3>
                    <p>
                        Nesta etapa programamos uma reunião estratégica 
                        para aprofundar nossa compreensão sobre os valores, 
                        objetivos e identidade da sua empresa. Esse diálogo 
                        é crucial para garantir que cada aspecto do seu site 
                        seja cuidadosamente alinhado com sua visão e 
                        necessidades específicas, resultando em uma 
                        representação digital autêntica e eficaz da sua empresa.
                    </p>
                </div>

                <div className={styles.definiçao} data-aos='fade-down'>
                    <h3><span className={styles.numeração}>2: </span>Criação do <span className={styles.textoGrifado}>Protótipo</span></h3>
                    <p>
                        Nessa etapa iremos criar um protótipo do seu site. A partir 
                        dessa etapa podemos ter uma noção de como você imagina o seu 
                        site e executarmos todas as ideias, requisitos e dinâmicas do 
                        seu site e sempre em contato com você para que você possa nos 
                        dar os seus feedbacks em cada etapa da construção.
                    </p>
                </div>

                <div className={styles.definiçao} data-aos='fade-left'>
                    <h3><span className={styles.numeração}>3: </span>Hora de botar a <span className={styles.textoGrifado}>Mão na massa</span></h3>
                    <p>
                        Essa etapa iremos partir para a construção oficial do seu site. 
                        Importante lembrar que essa etapa só é iniciada quando todas as 
                        outras forem completas, porque usaremos o seu protótipo para a 
                        construção oficial do seu site. A partir desse ponto não é recomendado 
                        haver alterações no design do site, por isso usamos a etapa do 
                        protótipo para deixar tudo esclarecido.
                    </p>
                </div>
            </div>
            <div className={styles.espaço}></div>
        </div>
    )
}