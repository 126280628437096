import { Link } from 'react-router-dom'
import styles from './Nav.module.css'
import logoDev from './logo.png'

export default function Nav(){
    return(
        <div className={styles.containerNav} id='nav'>
            <div className={styles.logo}>
                <img src={logoDev} alt="Logo da empresa"/>
            </div>
            <nav>
                <ul>
                    <li>
                        <Link
                            className={styles.link}
                            to="/">
                                Home
                        </Link>
                    </li>
                    <li>
                        <Link
                            className={styles.link}
                            to="/planos">
                                Nossos Serviços
                        </Link>
                    </li>
                    <li>
                        <Link
                            className={styles.link}
                            to="/cliente">
                                Clientes
                        </Link>
                    </li>
                    <li>
                        <Link
                            className={styles.linkContato}
                            to="/contato">
                                Contato
                        </Link>
                    </li>
                </ul>
            </nav>
        </div>
    )
}