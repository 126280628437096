import styles from './DetailsExtra.module.css'
import { FaCheck } from "react-icons/fa";

export default function DetailsExtra(){
    return(
        <div className={styles.container}>
            <h1>Conheça nossos <br/><span className={styles.textoGrifado}>Serviços Adicionais</span></h1>
            <div className={styles.containerExtra}>
                <div className={styles.detailsExtra}>
                    <h2>Criação de Logos</h2>
                    <span className={styles.creative}>Creative Zone</span>
                    <div className={styles.linhaVertical}></div>
                    <div className={styles.listExtra}>
                        <ul>
                            <li>
                                <i>
                                    <FaCheck 
                                        color='#DC143C'
                                    />
                                </i>
                                Logotipos Personalizadas
                            </li>
                            <li>
                                <i>
                                    <FaCheck 
                                        color='#DC143C'
                                    />
                                </i>
                                Design Exclusivo e Original
                            </li>
                            <li>
                                <i>
                                    <FaCheck 
                                        color='#DC143C'
                                    />
                                </i>
                                Suporte Técnico por 30 dias
                            </li>
                        </ul>
                    </div>
                </div>

                <div className={styles.detailsExtra}>
                    <h2>Criação de LinkMes</h2>
                    <span className={styles.bubble}>Bubble Zone</span>
                    <div className={styles.linhaVertical}></div>
                    <div className={styles.listExtra}>
                        <ul>
                            <li>
                                <i>
                                    <FaCheck 
                                        color='#7FFFD4'
                                    />
                                </i>
                                Domínio fixo incluso
                            </li>
                            <li>
                                <i>
                                    <FaCheck 
                                        color='#7FFFD4'
                                    />
                                </i>
                                Design Moderno
                            </li>
                            <li>
                                <i>
                                    <FaCheck 
                                        color='#7FFFD4'
                                    />
                                </i>
                                Integração com as Redes Sociais
                            </li>
                            <li>
                                <i>
                                    <FaCheck 
                                        color='#7FFFD4'
                                    />
                                </i>
                                Suporte Técnico por 30 dias
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className={styles.btnWapp}>
                <a href='https://wa.me/5511912125307'>Faça o seu orçamento</a>
            </div>
        </div>
    )
}