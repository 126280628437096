import styles from './Plans.module.css'

export default function Plans(){
    return(
        <div className={styles.containerPlans}>
            <h2>Nossos <span className={styles.textoGrifado}>Serviços</span></h2>
            <div className={styles.plans}>
                <div className={styles.detailsEssential} data-aos='flip-left'>
                    <h3>Essential Zone</h3>
                    <p>
                        Plano especificamente para o desenvolvimento de Landing pages.
                        Projeto de até 6 páginas, com um design moderno, carregamento otimizado, 
                        adaptação perfeita a diferentes dispositivos e garantindo navegabilidade eficiente.
                    </p>
                </div>

                <div className={styles.detailsPremium} data-aos='flip-up'>
                    <h3>Premium Zone</h3>
                    <p>
                        Plano especificamente para o desenvolvimento de Sites Institucionais.
                        Projeto de até 10 páginas, com adaptaçãopara diferentes dispositivos,
                        ideal para profissionais autonômos e empresas.
                    </p>
                </div>

                <div className={styles.detailsElite} data-aos='flip-right'>
                    <h3>Elite Zone</h3>
                    <p>
                        Plano especificamente para o desenvolvimento de Lojas Virtuais.
                        Projeto com número de páginas ilimitadas, com segurança reforçada
                        e integração de pagamentos online.
                    </p>
                </div>
            </div>
        </div>
    )
}